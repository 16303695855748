import { Avatar, FormControl, MenuItem, Select, SxProps, Theme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Services from 'utilities/Services';
import requests from './requests';

const FormControlStyle: SxProps<Theme> = {
  width: 100,
  marginBottom: 0,
  '& .MuiOutlinedInput-root:hover': {
    '& fieldset': {
      border: 'none',
    },
  },
};

const selectStyle: SxProps<Theme> = {
  width: 100,
  color: 'black',
  '& .MuiSelect-icon': {
    display: 'none',
  },
};

const LanguageSelector = () => {
  const { whoami, data } = Services.useAll();
  const me = whoami.useMe();
  const { i18n } = useTranslation();
  const initialLanguage = i18n.language;
  const [languagesAvailable, setLanguagesAvailable] = useState<any>(null);
  const [isLanguageLoading, setIsLanguageLoading] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(me.language ? me.language.code : initialLanguage);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const getLanguages = useCallback(async () => {
    setIsLanguageLoading(true);
    const response = await data.fetch(requests.getLanguages());
    setLanguagesAvailable(response);
    setIsLanguageLoading(false);
  }, [data]);

  const getLanguageFromStudent = useCallback(async () => {
    const defautLanguageFromNavigator = () => {
      switch (navigator.languages[0]) {
        case 'fr':
          return 'fr-FR';
        case 'en':
          return 'en-US';
        case 'en-US':
          return 'en-US';
        default:
          return 'fr-FR';
      }
    };
    console.log('defautLanguageFromNavigator', defautLanguageFromNavigator());
    me?.language ? setSelectedLanguage(me.language.code) : navigator.languages ? setSelectedLanguage(defautLanguageFromNavigator()) : setSelectedLanguage('fr-FR');
  }, [me.language]);

  useEffect(() => {
    console.log('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  const toggleLanguage = async (selectedCodeLanguage: string) => {
    setIsLanguageLoading(true);
    const language = selectedCodeLanguage.substring(0, 2);
    setSelectedLanguage(selectedCodeLanguage);
    changeLanguage(language);
    const languageCode = languagesAvailable.find((language: any) => language.code === selectedCodeLanguage);
    await data.fetch(requests.editStudent({ idLanguage: languageCode.id }));
    const newMe = await whoami.fetchMe(true);
    whoami.setMe(newMe);
    setIsLanguageLoading(false);
  }

  useEffect(() => {
    getLanguages();
    getLanguageFromStudent();
  }, [getLanguageFromStudent, getLanguages]);

  return (
    <>
      {
        (
          <FormControl sx={FormControlStyle}>
            <Select
              sx={selectStyle}
              value={selectedLanguage?.toLocaleLowerCase()}
              disabled={isLanguageLoading}
              onChange={(event: any) => {
                toggleLanguage(event.target.value);
              }}
            >
              {languagesAvailable?.map((language: any) => (
                <MenuItem key={language.code} value={(language.code)}>
                  <Avatar
                    src={language.flag}
                    sx={{ width: 50, height: 50 }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
    </>
  );
}

export default LanguageSelector;